import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function CreateNotification() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/notifications">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                Create Notification
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid>
        <div className="filters pb-2">
          <div className="create-notification-page">
            <div className="category-field">
              <div className="modal-input ">
                <label>Select Users</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Select " />
                </Form.Group>
              </div>
              <div className="modal-input ">
                <label>Title</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Type here " />
                </Form.Group>
              </div>
            </div>
            <div className="textarea-input">
              <Form.Group className="mt-4">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Type here" />
              </Form.Group>
            </div>
            <div className="create-btn">
              <button type="submit">Create</button>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>PIXINVENT</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
