import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  return (
    <aside>
      <div className="sidebar">
        <div className="logo-side">
          <Link to="/user">
            <img
              className="big"
              src={require("../../Assets/Images/side-bar-logo.svg").default}
            />
          </Link>
        </div>
        <div className="side-menu">
          <div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/dashboard"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/dashboard"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4159 7.98428L12.5273 4.18195C11.6447 3.49534 10.4087 3.49534 9.52611 4.18195L4.63661 7.98428C4.04115 8.44735 3.693 9.15954 3.69336 9.91386V16.5139C3.69336 17.5264 4.51417 18.3472 5.52669 18.3472H16.5267C17.5392 18.3472 18.36 17.5264 18.36 16.5139V9.91386C18.36 9.15945 18.0117 8.4472 17.4159 7.98428"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6673 13.7501C12.6415 14.972 9.35798 14.972 7.33398 13.7501"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Dashboard</p>
              </Link>
            </div>

            <div className="side-pages">
              <p>PAGES</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="11.0002"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> User Management</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/reports"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/reports  "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M8.25065 4.58301H6.41732C5.4048 4.58301 4.58398 5.40382 4.58398 6.41634V17.4163C4.58398 18.4289 5.4048 19.2497 6.41732 19.2497H15.584C16.5965 19.2497 17.4173 18.4289 17.4173 17.4163V6.41634C17.4173 5.40382 16.5965 4.58301 15.584 4.58301H13.7507"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="8.25"
                    y="2.75"
                    width="5.5"
                    height="3.66667"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.24935 15.5833V11"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.9993 15.5837V14.667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7493 15.583V12.833"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Reports</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/video-categories"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/video-categories"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.75 9.16641L17.9236 7.08008C18.2077 6.93812 18.545 6.95332 18.8151 7.12023C19.0853 7.28715 19.2498 7.58201 19.25 7.89958V14.0999C19.2498 14.4175 19.0853 14.7123 18.8151 14.8793C18.545 15.0462 18.2077 15.0614 17.9236 14.9194L13.75 12.8331V9.16641Z"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="2.75"
                    y="5.5"
                    width="11"
                    height="11"
                    rx="2"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Video Category</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/notifications"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/notifications"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 4.58333C9.1665 3.57081 9.98732 2.75 10.9998 2.75C12.0124 2.75 12.8332 3.57081 12.8332 4.58333C14.9783 5.59767 16.3886 7.71306 16.4998 10.0833V12.8333C16.6397 13.9891 17.3201 15.0097 18.3332 15.5833H3.6665C4.67953 15.0097 5.35994 13.9891 5.49984 12.8333V10.0833C5.61109 7.71306 7.02135 5.59767 9.1665 4.58333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 15.5835V16.5002C8.25 18.0189 9.48122 19.2502 11 19.2502C12.5188 19.2502 13.75 18.0189 13.75 16.5002V15.5835"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Notifications</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/support"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/support"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="14.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.6665 13.7498V10.9998C3.6665 6.94975 6.94975 3.6665 10.9998 3.6665C15.0499 3.6665 18.3332 6.94975 18.3332 10.9998V13.7498"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 17.4165C16.5 18.9353 14.0376 20.1665 11 20.1665"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Support</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
