import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

export default function Login() {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="left-banner-img"></div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Welcome to Vuexy! 👋🏻</h2>
                  <p>Please sign-in to your account and start the adventure</p>
                  <Form>
                    <div className="form-set">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email "
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 pass-eys"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          className="password-input"
                          type="password"
                          placeholder="Password"
                        />
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                        />
                      </Form.Group>
                    </div>
                    <div className="pass-rember-line">
                      {/* <div className="remember-check">
                        <input type="checkbox" class="red" id="filled-in-box" />
                        <Form.Label>Remember Me</Form.Label>
                      </div> */}
                      <Link to="/forgot-password" className="forgot">
                        Forgot Password?
                      </Link>
                    </div>
                    <Link
                      to="/otp-varification"
                      variant="primary"
                      type="submit"
                      className="submit"
                    >
                      Login
                    </Link>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
