import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function User() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Session</p>
                          <h2>
                            21,459 <span>(+29%)</span>
                          </h2>
                          <span className="total-users">Total Users</span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/session-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Paid Users</p>
                          <h2>
                            4,567 <span> (+18%) </span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/padi-user.svg").default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Active Users</p>
                          <h2>
                            19,860 <span>(-14%)</span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/active-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Pending Users</p>
                          <h2>
                            237<span>(+42%)</span>
                          </h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>User Management</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="input-select">
                  <Form.Select aria-label="Default select example">
                    <option> Profile Action </option>
                    <option value="1">1 Month</option>
                    <option value="2">6 Month</option>
                    <option value="3">1 year</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>10</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Profile picture</th>
                    <th>Profile name </th>
                    <th>Email</th>
                    <th>Profile Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-profile")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Meawe</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>
                          Temporary Banned
                          <span>
                            <img
                              src={
                                require("../Assets/Images/down-arrow.svg")
                                  .default
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-profile")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Meawe</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>
                          Temporary Banned
                          <span>
                            <img
                              src={
                                require("../Assets/Images/down-arrow.svg")
                                  .default
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-profile")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Meawe</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>
                          Temporary Banned
                          <span>
                            <img
                              src={
                                require("../Assets/Images/down-arrow.svg")
                                  .default
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-profile")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Meawe</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>
                          Temporary Banned
                          <span>
                            <img
                              src={
                                require("../Assets/Images/down-arrow.svg")
                                  .default
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-profile")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Meawe</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>
                          Temporary Banned
                          <span>
                            <img
                              src={
                                require("../Assets/Images/down-arrow.svg")
                                  .default
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div
                          className="user-profile"
                          onClick={() => navigate("/user-profile")}
                        >
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>Meawe</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending">
                        <p>
                          Temporary Banned
                          <span>
                            <img
                              src={
                                require("../Assets/Images/down-arrow.svg")
                                  .default
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>PIXINVENT</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
