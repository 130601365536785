import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function UserProfile() {
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                User Profile
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid>
        <div className="my-profile-page mt-4">
          <div className="profile-top-box">
            <div className="photo-upload">
              <div className="profile-picture">
                <img src={require("../Assets/Images/my-profile.svg").default} />
                <div className="profile-info mt-3">
                  <p>Profile info</p>
                </div>
              </div>
            </div>

            <div className="profile-disp mt-2">
              <div className="form-set input-profile">
                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Profile name</Form.Label>
                  <Form.Control type="type" placeholder="Dummy" />
                </Form.Group>

                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Dummy" />
                </Form.Group>
              </div>

              <div className="form-set input-profile mt-2">
                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicEmail"
                >
                  <Form.Label>User name</Form.Label>
                  <Form.Control type="text" placeholder="Dummy" />
                </Form.Group>

                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Bio</Form.Label>
                  <Form.Control type="text" placeholder="Dummy" />
                </Form.Group>
              </div>

              <div className="bank-det-heading mt-3">
                <h2>Bank details</h2>
              </div>
              <div className="form-set input-profile mt-2">
                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Account holder name</Form.Label>
                  <Form.Control type="number" placeholder="Dummy" />
                </Form.Group>

                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Bank name</Form.Label>
                  <Form.Control type="number" placeholder="Dummy" />
                </Form.Group>
              </div>

              <div className="form-set input-profile mt-2">
                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Account number</Form.Label>
                  <Form.Control type="number" placeholder="Dummy" />
                </Form.Group>

                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicPassword"
                >
                  <Form.Label>IFSC code</Form.Label>
                  <Form.Control type="number" placeholder="Dummy" />
                </Form.Group>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={6}>
              <Form>
                <div className="profile-top-box mt-4">
                  <div className="heading-profile">
                    <h2>Activity</h2>
                  </div>
                  <div className="activity-list-user">
                    <ul>
                      <li>
                        <p>[Admin Name] made updates to profile.</p>
                        <span>01/01/2023 13:32</span>
                      </li>
                      <li>
                        <p>[Admin Name] made updates to profile.</p>
                        <span>01/01/2023 13:32</span>
                      </li>
                      <li>
                        <p>[Admin Name] made updates to profile.</p>
                        <span>01/01/2023 13:32</span>
                      </li>
                      <li>
                        <p>[Admin Name] made updates to profile.</p>
                        <span>01/01/2023 13:32</span>
                      </li>
                      <li>
                        <p>[Admin Name] made updates to profile.</p>
                        <span>01/01/2023 13:32</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Form>
            </Col>

            <Col lg={6}>
              <Row>
                <Col lg={12}>
                  <div className="profile-top-box mt-4">
                    <div className="heading-profile">
                      <h2>Delete Account</h2>
                      <p>
                        Once you delete your account, you can not retrieve the
                        account. Please be certain.
                      </p>
                      <div className="btn-up mt-2">
                        <button type="button">Delete Account</button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="profile-top-box mt-4">
                    <div className="heading-profile">
                      <h2>Suspend Account</h2>
                      <p>
                        You will not be able to receive messages, notifications
                        for up to 24hours.
                      </p>
                      <div className="btn-up mt-2">
                        <button type="button">Suspend Account</button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
