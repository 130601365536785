import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function Support() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Support</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>10</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
                <div className="create-date-range">
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/upload-white-icon.svg")
                            .default
                        }
                      />
                    </span>
                    Create Date Range
                  </p>
                </div>
                <div className="export-box download-btn-st">
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/download-icon.svg").default
                        }
                      />
                    </span>
                    Download
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Ticket iD</th>
                    <th>Date</th>
                    <th>Sender name</th>
                    <th>Email</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>
                      <div
                        className="user-id pointer"
                        onClick={() => navigate("/chat-support")}
                      >
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending close">
                        <p>Closed</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="checked-in open">
                        <p>Open</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending close">
                        <p>Closed</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="checked-in open">
                        <p>Open</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="pending close">
                        <p>Closed</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>XYZ123</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p> 22-Jan-2024</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Jordan Stevenson</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-role">
                        <p>abc@domain.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>I am trying to delete my wro...</p>
                      </div>
                    </td>
                    <td>
                      <div className="checked-in open">
                        <p>Open</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>PIXINVENT</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
