import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function Notifications() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Notification</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>10</option>
                    <option value="1">9</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search User" />
                    </Form.Group>
                  </Form>
                </div>
                <div className="create-new-btn">
                  <button onClick={() => navigate("/create-notification")}>
                    Create New
                  </button>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Sent to</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>warnings</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Hey you! New products are waiting.</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>James Mathew</p>
                      </div>
                    </td>

                    <td>
                      <div className="open close">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>warnings</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Hey you! New products are waiting.</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>James Mathew</p>
                      </div>
                    </td>

                    <td>
                      <div className="open close">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>warnings</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Hey you! New products are waiting.</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>James Mathew</p>
                      </div>
                    </td>

                    <td>
                      <div className="open close">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>warnings</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Hey you! New products are waiting.</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>James Mathew</p>
                      </div>
                    </td>

                    <td>
                      <div className="open close">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>warnings</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Hey you! New products are waiting.</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>James Mathew</p>
                      </div>
                    </td>

                    <td>
                      <div className="open close">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="user-id">
                        <p>warnings</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>Hey you! New products are waiting.</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>James Mathew</p>
                      </div>
                    </td>

                    <td>
                      <div className="open close">
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>Showing 1 to 10 of 50 entries</p>
                </div>
                <div className="pagination-block">
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item active>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>PIXINVENT</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
